import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#016eb8',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,

        'text-primary': '#fff',
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
