import APIError from '@/services/api/error';
import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import Vue from 'vue';

class APIService extends Vue {
  private instance!: AxiosInstance;

  constructor(baseURL: string) {
    super();

    const axiosConfig: AxiosRequestConfig = {};
    axiosConfig.baseURL = baseURL;

    const instance: AxiosInstance = axios.create(axiosConfig);
    this.instance = instance;
  }

  public GET(url: string, config?: AxiosRequestConfig):
    Promise<AxiosResponse|APIError> {
    return this.instance.get(url, config)
      .catch(APIService.handleError);
  }

  public POST(url: string, data: unknown, config?: AxiosRequestConfig):
    Promise<AxiosResponse|APIError> {
    return this.instance.post(url, data, config)
      .catch(APIService.handleError);
  }

  public PUT(url: string, data: unknown, config?: AxiosRequestConfig):
    Promise<AxiosResponse|APIError> {
    return this.instance.put(url, data, config)
      .catch(APIService.handleError);
  }

  public DELETE(url: string, config?: AxiosRequestConfig):
    Promise<AxiosResponse|APIError> {
    return this.instance.delete(url, config)
      .catch(APIService.handleError);
  }

  private static handleError(err: AxiosError): APIError {
    if (!err.response) {
      return new APIError('APIError', err.message);
    }

    // client received an error response (5xx, 4xx)
    let { message, extra, type } = err.response.data;
    if (!message) {
      message = err.response.statusText;
    }
    if (!extra) {
      extra = err.stack;
    }
    if (!type) {
      type = 'APIError';
    }

    // Default error
    const apiErr = new APIError(type, message, extra, err.response.status);
    return apiErr;
  }
}

export default new APIService(process.env.VUE_APP_API_BASE_URL);
