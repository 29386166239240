import APIService from '@/services/api';
import APIError from '@/services/api/error';

interface CreateLeadRequest {
  name: string;
  email: string;
}

interface VerifyLeadRequest {
  token: string;
}

interface UnsubscribeLeadRequest {
  email: string;
}

export default class LeadsAPI {
  public static async CreateLead(req: CreateLeadRequest): Promise<boolean | APIError> {
    return APIService.POST('/v1-pre/leads/CreateLead', req)
      .then((resp) => {
        if (resp instanceof APIError) {
          return resp;
        }

        // Lead was created or already exists
        return resp.status === 201 || resp.status === 200;
      });
  }

  public static async VerifyLead(req: VerifyLeadRequest):
    Promise<boolean | APIError> {
    return APIService.POST('/v1-pre/leads/VerifyLead', req)
      .then((resp) => {
        if (resp instanceof APIError) {
          return resp;
        }

        // Lead was verified
        return resp.status === 200;
      });
  }

  public static async UnsubscribeLead(req: UnsubscribeLeadRequest):
    Promise<boolean | APIError> {
    return APIService.POST('/v1-pre/leads/UnsubscribeLead', req)
      .then((resp) => {
        if (resp instanceof APIError) {
          return resp;
        }

        // Lead was unsubscribed
        return resp.status === 200;
      });
  }
}
