




























































import { Component, Vue } from 'vue-property-decorator';
import LeadsAPI from '@/services/api/modules/leads';
import Header from '@/components/Header.vue';
import APIError from '@/services/api/error';

@Component({
  components: {
    Header,
  },
})
export default class Verify extends Vue {
  private email = ''

  private valid = false

  private loading = false

  private error = ''

  private unsubscribed = false

  private emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];

  mounted() {
    const { email } = this.$route.params;
    if (!email) {
      return;
    }

    this.email = atob(email);
  }

  async submit() {
    // Validate
    const { unsubscribeForm } = this.$refs;
    if (!(unsubscribeForm as Vue).validate()) {
      return;
    }

    this.loading = true;
    this.error = '';

    const { email } = this;
    const resp = await LeadsAPI.UnsubscribeLead({ email });

    this.loading = false;

    if (resp instanceof APIError || resp === false) {
      this.error = 'An error occurred while unsubscribing. Please try again.';
      return;
    }

    // All good, show enrolled
    this.unsubscribed = true;
  }
}
