export default class APIError {
  public type!: string;

  public message!: string;

  public extra!: Array<string>;

  public statusCode!: number;

  constructor(type: string, message: string, extra: Array<string> = [], statusCode = 0) {
    this.type = type;
    this.message = message;
    this.extra = extra;
    this.statusCode = statusCode;
  }

  public string(): string {
    const type = this.type || 'APIError';
    return `[${type}] ${this.message}`;
  }
}
