
























import { Component, Vue } from 'vue-property-decorator';
import LeadsAPI from '@/services/api/modules/leads';
import Header from '@/components/Header.vue';
import APIError from '@/services/api/error';

@Component({
  components: {
    Header,
  },
})
export default class Verify extends Vue {
  private error = ''

  async mounted() {
    const { token } = this.$route.params;
    if (!token) {
      return;
    }

    const resp = await LeadsAPI.VerifyLead({ token });
    if (resp instanceof APIError || resp === false) {
      this.error = 'An error occurred while confirming your subscription. Please try again.';
    }

    this.$router.replace({ name: 'verified' });
  }
}
