import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Verify from '@/views/Verify.vue';
import Verified from '@/views/Verified.vue';
import Unsubscribe from '@/views/Unsubscribe.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/verify/:token',
    name: 'verify',
    component: Verify, // do not lazily import so this route is super fast
  },
  {
    path: '/verified',
    name: 'verified',
    component: Verified, // do not lazily import so this route is super fast
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: Unsubscribe, // do not lazily import so this route is super fast
  },
  {
    path: '/unsubscribe/:email',
    name: 'unsubscribe.email',
    component: Unsubscribe, // do not lazily import so this route is super fast
  },
  {
    path: '*',
    redirect: {
      name: 'home',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
